import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subcatalogue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubCatalogueGrid = _resolveComponent("SubCatalogueGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SubCatalogueGrid, {
      parentModel: _ctx.parentModel,
      controller: 'UserRole',
      columns: _ctx.columns,
      editingConfig: _ctx.getChangedConfig,
      selectionConfig: _ctx.selectionConfig,
      designConfig: _ctx.designConfig,
      popupCreateEditComponent: _ctx.userRoleCreateEditDialog,
      pushDataToSelectControl: true
    }, null, 8, ["parentModel", "columns", "editingConfig", "selectionConfig", "designConfig", "popupCreateEditComponent"])
  ]))
}