
import { defineComponent } from "vue";
import SubCatalogueGrid from "@/components/controls/catalogueGrid/SubCatalogueGrid.vue";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import userRoleCreateEditDialog from "@/components/catalogues/UserCatalogue/UserRoleCreateEditDialog.vue";
import { CatalogueColumnConfig } from "@/components/controls/catalogueGrid/columns/catalogueColumnConfig";

export default defineComponent({
  components: {
    SubCatalogueGrid,
  },
  props: {
    parentModel: { required: true },
  },
  methods: {},
  computed: {
    userRoleCreateEditDialog() {
      return userRoleCreateEditDialog;
    },
    getChangedConfig() {
      return this.editingConfig;
    },
  },
  data() {
    return {
      columns: [
        new CatalogueColumnConfig({
          dataField: "roleDescription",
          caption: "Роль",
          modelIdField: "roleId",
          modelDescField: "roleDescription",
          keyField: "id",
          displayField: "roleDescription",
          catalogueComponentName: "catalogues/RoleCatalogue/RoleCatalogue",
          descriptionColumnName: "roleDescription",
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: false,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        height: "300px",
        pagingMode: "scroll",
        heightScroll: "300px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
        mode: "row",
      }),
    };
  },
});
